import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { withPage } from "../PageContainer";

class LandingPage extends React.Component {
  render() {
    return (
      <Wrapper>
        <img className="banner" src={"./images/002.png"} width="100%" />
        <div className="btns-wrapper">
          <div className="row">
            <Btn to="/about">
              <p>關於空軍</p>
            </Btn>
            <Btn to="/planes">
              <p>重要機型</p>
            </Btn>
          </div>
          <div className="row">
            <Btn to="/war-list">
              <p>重要戰役</p>
            </Btn>
            <Btn to="/timeline">
              <p>大事紀要</p>
            </Btn>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img className="banner" src={"./images/006.png"} width="30%" />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: white;
  padding: 30px;

  & > .banner {
    background-color: lightgray;
    border-radius: 30px 30px 0px 0px;
  }

  & > .btns-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    & > .row {
      display: flex;
      flex-direction: row;
    }
  }
`;

const Btn = styled(Link)`
  flex: 1;
  padding: 25px 0px;
  margin: 10px;
  border: 4px solid #3e3a39;
  border-radius: 20px;
  text-decoration: none;

  & > p {
    font-family: "GenSekiGothic-B";
    color: #3e3a39;
    text-align: center;
    font-size: 45px;
    letter-spacing: 6pt;
  }
  :active {
    background-color: #06347a;
    & > p {
      color: #fff;
    }
  }
`;

export default withPage(LandingPage);
